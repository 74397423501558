import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { bool } from 'prop-types'
import { rgba } from 'polished'
import { Transition } from 'react-transition-group'
import { useIntl, changeLocale } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { boxShadow, square, cover } from 'styles/helpers'
import { greyDark, black, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { animationTime, animationCurve, borderRadius } from 'styles/variables'

import Icon from 'components/Icon'
import Portal from 'components/Portal'
import MobileNavItem from './MobileNavItem'
import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  overflow: auto;
  transition: all ${animationTime} ${animationCurve};
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  background-color: ${rgba(black, 0.6)};
  overflow-x: hidden;
  ${cover('fixed')};

  ${(props) =>
    props.animationState === 'entering' ||
    (props.animationState === 'entered' &&
      css`
        opacity: 1;
        visibility: visible;
      `)}

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}

  ${(props) =>
    props.animationState === 'exiting' ||
    (props.animationState === 'exited' &&
      css`
        opacity: 0;
        visibility: hidden;
      `)}
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
  background: ${white};
  transition: transform ${animationTime} ${animationCurve},
    opacity ${animationTime} ${animationCurve};
  background-color: ${white};
  overflow: hidden;
  transform: translateX(15%);
  border-radius: ${borderRadius};
  padding: ${spacing.large};
  opacity: 0;
  ${boxShadow};

  ${media.small`
    max-width: 300px;
  `};

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
      opacity: 1;
    `}
`

const Toggle = styled.button`
  display: flex;
  padding: ${spacing.small};
  margin: -${spacing.small};
  color: ${white};
  ${square('40px')};

  ${(props) =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  ${media.large`
    display: none;
  `};
`

const Close = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  color: ${greyDark};
  padding: ${spacing.large};
`

const query = graphql`
  query {
    prismic {
      allApartmentcategorys(sortBy: sort_ASC) {
        edges {
          node {
            description
            title
            _meta {
              uid
              lang
            }
          }
        }
      }
    }
  }
`

const MobileNav = ({ isDark, pageAlternativeLangPath, ...rest }) => {
  const [visible, setVisible] = useState(false)

  const data = useStaticQuery(query)

  const {
    prismic: { allApartmentcategorys },
  } = data

  const intl = useIntl()
  const otherLang = ['fr-ca', 'en-us'].find((l) => l !== intl.locale)

  const handleChangeLocale = () => {
    setVisible(!visible)

    changeLocale(otherLang, pageAlternativeLangPath)
  }

  const apartmentLinks = allApartmentcategorys.edges
    .filter(({ node }) => {
      return node._meta.lang === intl.locale
    })
    .map(({ node }) => ({
      label: node.title,
      link: `/apartments#${node._meta.uid}`,
    }))

  return (
    <>
      <Toggle
        onClick={() => {
          setVisible(!visible)
        }}
        isDark={isDark}
      >
        <Icon icon='hamburger' />
      </Toggle>
      <Portal selector='nav-portal'>
        <Transition in={visible} timeout={0} unmountOnExit enter>
          {(state) => (
            <Container animationState={state} {...rest}>
              <Inner animationState={state}>
                <MobileNavItem
                  link='/'
                  label={intl.formatMessage({ id: 'home' })}
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem
                  label={intl.formatMessage({ id: 'apartment.many' })}
                  link='/apartments'
                  onClick={() => {
                    setVisible(!visible)
                  }}
                  subLinks={apartmentLinks}
                />
                <MobileNavItem
                  label={intl.formatMessage({ id: 'offers' })}
                  link='/offers'
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem
                  link='/concept'
                  label='Concept'
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem link='/bar' label='Bar' />
                <MobileNavItem
                  href='https://ghdepanneur.com/'
                  label={intl.formatMessage({ id: 'convenienceStore' })}
                  tag='href'
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem
                  link='/explore-montreal'
                  label={intl.formatMessage({ id: 'footer.exploreMontreal' })}
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem
                  link='/contact'
                  label='Contact'
                  onClick={() => {
                    setVisible(!visible)
                  }}
                />
                <MobileNavItem
                  tag='button'
                  label={otherLang.split('-')[0].toUpperCase()}
                  onClick={handleChangeLocale}
                />
                <Close
                  onClick={() => {
                    setVisible(false)
                  }}
                >
                  <Icon icon='close' />
                </Close>
              </Inner>
            </Container>
          )}
        </Transition>
      </Portal>
    </>
  )
}

MobileNav.propTypes = {
  isDark: bool,
}

export default MobileNav
