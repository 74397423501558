import styled from 'styled-components'

import { fontWeights, fontSizes } from 'styles/typography'

export default styled.span`
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  font-size: ${fontSizes.tiny};
  letter-spacing: 0.1em;
`
