import React from 'react'
import styled from 'styled-components'
import { node, string, bool } from 'prop-types'
import classnames from 'classnames'
import { stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  padding-left: ${stripUnit(spacing.medium) * 0.75 + 'px'};
  padding-right: ${stripUnit(spacing.medium) * 0.75 + 'px'};
  margin-bottom: ${spacing.large};

  ${media.medium`
    padding-left: ${spacing.medium};
    padding-right: ${spacing.medium};
    margin-bottom: ${spacing.xLarge};
  `};
`

const GridItem = ({
  xs,
  sm,
  pushSm,
  md,
  pushMd,
  lg,
  pushLg,
  xlg,
  equalHeight,
  className,
  children,
}) => (
  <Container
    className={classnames(className, equalHeight && 'u-flex', {
      [`u-${xs}`]: xs,
      [`u-${sm}-sm`]: sm,
      [`u-push-${pushSm}-sm`]: pushSm,
      [`u-${md}-md`]: md,
      [`u-push-${pushMd}-md`]: pushMd,
      [`u-${lg}-lg`]: lg,
      [`u-push-${pushLg}-lg`]: pushLg,
      [`u-${xlg}-lg`]: xlg,
    })}
  >
    {children}
  </Container>
)

GridItem.propTypes = {
  children: node,
  xs: string.isRequired,
  sm: string,
  pushSm: string,
  md: string,
  pushMd: string,
  lg: string,
  pushLg: string,
  xlg: string,
  className: string,
  equalHeight: bool,
}

export default GridItem
