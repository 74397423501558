// Primary

export const gold = '#C59B78'
export const green = '#45D8A7'

// Greys

export const greyDark = '#222'
export const grey = '#555'
export const greyLight = '#999'
export const greyLightest = '#ddd'
export const offWhite = '#f4f4f4'
export const red = 'tomato'

// Misc

export const white = '#fff'
export const black = '#000'

// Set colors

export const primaryColor = gold
export const backgroundColor = offWhite
export const borderColor = greyLightest
