import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { rgba, stripUnit } from 'polished'
import { useIntl, changeLocale, Link } from 'gatsby-plugin-intl'

import { media, mediaDown } from 'styles/media'
import { boxShadow } from 'styles/helpers'
import { black, white, greyDark } from 'styles/colors'
import {
  navHeight,
  navHeightMobile,
  animationTime,
  animationCurve,
} from 'styles/variables'
import * as spacing from 'styles/spacing'

import Logo from 'assets/images/logo.svg'
import BookButton from 'components/button/BookButton'
import MobileNav from 'components/MobileNav'
import NavItem from './NavItem'
import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navHeightMobile};
  padding: 0 ${spacing.large};
  transition: background-color ${animationTime} ${animationCurve};
  z-index: 400;

  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
      background-color: ${rgba(white, 0.92)};
      backdrop-filter: blur(8px);
      ${boxShadow};
    `}

  ${media.small`
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${media.medium`
    height: ${navHeight};
  `};

  ${media.xLarge`
    padding-left: ${spacing.xLarge};
    padding-right: ${spacing.xLarge};
  `};
`

const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${spacing.small};
  margin: -${spacing.small};
`

const StyledLogo = styled(Logo)`
  display: block;
  width: 78px;
  height: 35px;
  fill: ${white};
  transition: fill ${animationTime} ${animationCurve};

  ${(props) =>
    props.isFixed &&
    css`
      fill: ${black};
    `}

  ${media.medium`
    width: 84px;
    height: 38px;
  `};

  ${media.large`
    width: 100px;
    height: 45px;
  `};
`

const List = styled.div`
  display: flex;
  align-items: center;

  /* ${media.large`
    margin-left: 150px;
  `};

  ${media.xLarge`
    margin-left: 160px;
  `}; */
`

const ButtonWrap = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaDown.large`
    margin-left: auto;
    margin-right: ${spacing.medium};
  `};
`

const Phone = styled.a`
  color: ${white};
  transition: color ${animationTime} ${animationCurve};
  /* margin-right: ${spacing.large}; */

  /* ${media.large`
    margin-right: ${spacing.xLarge};
  `}; */

  ${(props) =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}
`

const LangSwitcher = styled.button`
  color: ${white};
  padding: 0 ${stripUnit(spacing.small) * 1.5 + 'px'};
  transition: color ${animationTime} ${animationCurve};

  ${(props) =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  ${media.large`
    padding: 0 ${spacing.medium};
  `};
`
const query = graphql`
  query {
    prismic {
      allApartmentcategorys(sortBy: sort_ASC) {
        edges {
          node {
            description
            title
            _meta {
              uid
              lang
            }
          }
        }
      }
    }
  }
`

const Nav = ({ pageAlternativeLangPath, isBar }) => {
  const [fixedNav, setFixedNav] = useState(false)

  const data = useStaticQuery(query)

  const {
    prismic: { allApartmentcategorys },
  } = data

  const handleScroll = useCallback(() => {
    window.pageYOffset > 0 ? setFixedNav(true) : setFixedNav(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const intl = useIntl()

  const otherLang = ['fr-ca', 'en-us'].find((l) => l !== intl.locale)

  const handleChangeLocale = () => {
    changeLocale(otherLang, pageAlternativeLangPath)
  }

  const apartmentLinks = allApartmentcategorys.edges
    .filter(({ node }) => {
      return node._meta.lang === intl.locale
    })
    .map(({ node }) => ({
      label: node.title,
      link: `/apartments#${node._meta.uid}`,
    }))

  return (
    <Container isFixed={fixedNav}>
      <LogoWrap to='/'>
        <StyledLogo isFixed={fixedNav} />
      </LogoWrap>

      <List className='u-hide-large-down'>
        <NavItem
          label={intl.formatMessage({ id: 'apartment.many' })}
          link='/apartments'
          isDark={fixedNav}
          subLinks={apartmentLinks}
        />
        <NavItem
          link={`/offers`}
          label={intl.formatMessage({ id: 'offers' })}
          isDark={fixedNav}
        />
        <NavItem link={`/concept`} label='Concept' isDark={fixedNav} />
        <NavItem link='/bar' label='Bar' isDark={fixedNav} />
        <NavItem
          link={`/explore-montreal`}
          label={intl.formatMessage({ id: 'footer.exploreMontreal' })}
          isDark={fixedNav}
        />
        <NavItem link={`/contact`} label='Contact' isDark={fixedNav} />
        <LangSwitcher isDark={fixedNav} onClick={handleChangeLocale}>
          {otherLang.split('-')[0].toUpperCase()}
        </LangSwitcher>
      </List>

      <ButtonWrap>
        {!isBar && <BookButton isSmall />}

        <Phone
          isDark={fixedNav}
          href='tel:5143193030'
          className='u-hide-large-down'
        >
          1-855-577-0222
        </Phone>
      </ButtonWrap>

      <MobileNav
        pageAlternativeLangPath={pageAlternativeLangPath}
        isDark={fixedNav}
      />
    </Container>
  )
}

export default Nav
