import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'

const Container = styled.section`
  display: block;
  /* ${verticalPadding`64px`}; */
  margin-top: 64px;
    margin-bottom: 64px;

  ${props =>
    props.flushTop &&
    css`
      padding-margin: 0 !important;
    `}

  ${media.small`
    margin-top: 80px;
    margin-bottom: 80px;
    /* ${verticalPadding`80px`}; */
  `};

  ${media.medium`
    margin-top: 100px;
    margin-bottom: 100px;
    /* ${verticalPadding`100px`}; */
  `};

  ${media.large`
    margin-top: 160px;
    margin-bottom: 160px;
    /* ${verticalPadding`160px`}; */
  `};

  ${media.xLarge`
  margin-top: 200px;
    margin-bottom: 200px;
    /* ${verticalPadding`200px`}; */
  `};
`

const Block = styled.div`
  position: relative;

  & + & {
    margin-top: 32px;

    ${media.small`
      margin-top: 40px;
    `};

    ${media.medium`
      margin-top: 48px;
    `};

    ${media.large`
      margin-top: 64px;
    `};

    ${media.xLarge`
      margin-top: 80px;
    `};
  }
`

const Section = ({ children, flushTop, ...props }) => (
  <Container flushTop={flushTop} {...props}>
    {children}
  </Container>
)

Section.propTypes = {
  children: node.isRequired,
  flushTop: bool,
}

export const SectionBlock = ({ children }) => <Block>{children}</Block>

SectionBlock.propTypes = {
  children: node.isRequired,
}

export default Section
