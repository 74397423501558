// Animation
export const animationTime = '0.3s'
export const animationCurve = 'ease-in-out'

// Border Radius
export const borderRadius = '0px'

// Inputs
export const inputHeight = '48px'
export const inputHeightDesktop = '56px'
export const inputHeightSmall = '36px'

// Nav
export const navHeightMobile = '80px'
export const navHeight = '108px'
