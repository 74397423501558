import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/founders-grotesk-web-regular.eot');
    src: url('/fonts/founders-grotesk-web-regular.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/founders-grotesk-web-regular.woff2') format('woff2'),
      url('/fonts/founders-grotesk-web-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/founders-grotesk-web-medium.eot');
    src: url('/fonts/founders-grotesk-web-medium.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/founders-grotesk-web-medium.woff2') format('woff2'),
      url('/fonts/founders-grotesk-web-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/founders-grotesk-web-bold.eot');
    src: url('/fonts/founders-grotesk-web-bold.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/founders-grotesk-web-bold.woff2') format('woff2'),
      url('/fonts/founders-grotesk-web-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
`
