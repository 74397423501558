import React from 'react'
import { number, string } from 'prop-types'
import styled, { css } from 'styled-components'

import {
  fontWeights,
  fontSizes,
  fontFamilies,
  lineHeights,
} from 'styles/typography'
import { greyDark } from 'styles/colors'
import { media } from 'styles/media'

const getFontSize = size => {
  if (size === 1)
    return css`
      font-size: 42px;
      line-height: 1;

      ${media.small`
        font-size: 50px;
      `};

      ${media.medium`
        font-size: 58px;
      `};

      ${media.large`
        font-size: 64px;
      `};

      ${media.xLarge`
        font-size: 72px;
      `};
    `

  if (size === 2)
    return css`
      font-size: 32px;
      line-height: 1.1;

      ${media.small`
        font-size: 36px;
      `};

      ${media.medium`
        font-size: 40px;
      `};

      ${media.large`
        font-size: 44px;
      `};

      ${media.xLarge`
        font-size: 48px;
      `};
    `

  if (size === 3)
    return css`
      font-size: 24px;
      line-height: 1.25;

      ${media.small`
        font-size: 26px;
      `};

      ${media.medium`
        font-size: 28px;
      `};

      ${media.large`
        font-size: 30px;
      `};

      ${media.xLarge`
        font-size: 32px;
      `};
    `

  if (size === 4)
    return css`
      font-size: 21px;
      line-height: 1.25;
      margin-bottom: 0.75em;

      ${media.small`
        font-size: 22px;
      `};

      ${media.medium`
        font-size: 23px;
      `};

      ${media.large`
        font-size: 24px;
      `};
    `

  if (size === 6)
    return css`
      font-size: 11px;
      line-height: 1.25;
      margin-bottom: 0.5em;
      font-family: ${fontFamilies.body};
      font-weight: ${fontWeights.bold};
      text-transform: uppercase;
      letter-spacing: 0.1em;

      ${media.medium`
        font-size: ${fontSizes.tiny};
      `};
    `
}

const Container = styled.div`
  font-family: ${fontFamilies.heading};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.heading};
  color: ${greyDark};
  margin-bottom: 0.25em;
  ${props => getFontSize(props.size)};

  ${props =>
    props.variant === 'sans' &&
    css`
      font-family: ${fontFamilies.body};
      font-weight: ${fontWeights.medium};
    `}

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = ({ size = 3, variant, ...props }) => (
  <Container as={`h${size}`} size={size} variant={variant} {...props} />
)

Heading.propTypes = {
  size: number,
  variant: string,
}

export default Heading
