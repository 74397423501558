import React from 'react'
import styled from 'styled-components'
// import { Link } from 'gatsby'
import { rgba, stripUnit } from 'polished'
import { useIntl, Link } from 'gatsby-plugin-intl'

import { media, mediaDown } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSizes } from 'styles/typography'
import { white, greyDark } from 'styles/colors'

import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import Wrapper from 'components/Wrapper'
import SocialLinks from 'components/SocialLinks'
import SmallCaps from 'components/SmallCaps'
import Logo from 'assets/images/logo.svg'
import { graphql, useStaticQuery } from 'gatsby'
import OrigineLogo from 'assets/images/origine-logo.svg'

const currentYear = new Date().getFullYear()

const query = graphql`
  query {
    prismic {
      allApartments(sortBy: sort_ASC) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
          }
        }
      }
    }
  }
`

const Container = styled.footer`
  background-color: ${greyDark};
  padding-top: 64px;
  padding-bottom: 100px;

  ${media.small`
    padding-top: 72px;
    padding-bottom: 120px;
  `};

  ${media.medium`
    padding-top: 88px;
    padding-bottom: 180px;
  `};

  ${media.large`
    padding-top: 100px;
    padding-bottom: 200px;
  `};

  ${media.xLarge`
    padding-top: 120px;
    padding-bottom: 240px;
  `};
`

const StyledLogo = styled(Logo)`
  display: block;
  width: 72px;
  height: 32px;
  height: auto;
  fill: ${white};
  margin-bottom: ${spacing.large};

  ${media.medium`
    width: 84px;
    height: 38px;
    margin-bottom: ${spacing.large};
  `};

  ${media.large`
    width: 100px;
    height: 45px;
    margin-bottom: ${spacing.xLarge};
  `};
`

const StyledOrigineLogo = styled(OrigineLogo)`
  display: block;
  width: 72px;
  height: 32px;
  height: auto;
  fill: ${white};
  margin-bottom: ${spacing.large};

  ${media.medium`
    width: 84px;
    height: 38px;
    margin-bottom: ${spacing.large};
  `};

  ${media.large`
    width: 100px;
    height: 45px;
    margin-bottom: ${spacing.xLarge};
  `};
`

const Heading = styled(SmallCaps)`
  display: block;
  color: ${rgba(white, 0.5)};
  margin-bottom: ${spacing.small};

  ${media.small`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const CopyrightWrap = styled(GridItem)`
  position: static;

  ${mediaDown.medium`
    order: 6;
  `};
`

const Copyright = styled.span`
  display: block;
  color: ${rgba(white, 0.7)};
  font-size: ${fontSizes.small};
`

const FooterLink = styled(Link)`
  color: ${white};
  display: block;
  line-height: 1.75;
`
const FooterEternalLink = styled.a`
  color: ${white};
  display: block;
  line-height: 1.75;
`

const Address = styled.a`
  display: block;
  color: ${white};
  line-height: 1.75;
`

// const NewsletterWrap = styled.div`
//   position: static;

//   ${media.medium`
//     max-width: 440px;
//   `};

//   ${media.xLarge`
//     margin-top: ${spacing.medium};
//   `};
// `

const Social = styled.div`
  color: ${white};
  margin-bottom: ${spacing.large};

  ${media.large`
    margin-bottom: ${spacing.xLarge};
  `};
`

const Footer = () => {
  const intl = useIntl()

  const data = useStaticQuery(query)

  const {
    prismic: { allApartments },
  } = data

  const apartmentLinks = allApartments.edges
    .filter(({ node }) => {
      return node._meta.lang === intl.locale
    })
    .map(({ node }) => ({
      label: node.title,
      link: `/apartments/${node._meta.uid}`,
    }))

  return (
    <Container>
      <Wrapper>
        <GridRow>
          <CopyrightWrap xs='1-1' md='1-4' xlg='1-2'>
            <StyledLogo />
            <Social>
              <SocialLinks
                items={[
                  {
                    link: 'https://www.facebook.com/griffintownhotel/',
                    icon: 'facebook',
                  },
                  { link: 'https://twitter.com/griffintownh', icon: 'twitter' },
                  {
                    link: 'https://www.instagram.com/griffintownhotel/',
                    icon: 'instagram',
                  },
                  {
                    link: 'mailto:reservation@griffintownhotel.com',
                    icon: 'mail',
                  },
                ]}
              />
            </Social>
            <StyledOrigineLogo />
            <Copyright>
              CITQ: 300840
              <br />© {currentYear} Hotel Griffintown.
              <br className='u-hide-medium-down' />{' '}
              <a
                href='https://storage.googleapis.com/obarhotel/politique%20de%20confidentialite%CC%81.pdf'
                target='_blank'
              >
                {intl.formatMessage({ id: 'footer.rights' })}
              </a>
            </Copyright>
          </CopyrightWrap>
          <GridItem xs='1-2' sm='1-3' md='1-4' xlg='1-6'>
            <Heading>{intl.formatMessage({ id: 'footer.browse' })}</Heading>
            <FooterLink to='/apartments'>
              {intl.formatMessage({ id: 'apartment.many' })}
            </FooterLink>
            <FooterLink to='/concept'>Concept</FooterLink>
            <FooterLink to='/explore-montreal'>
              {intl.formatMessage({ id: 'footer.exploreMontreal' })}
            </FooterLink>
            <FooterLink to='/contact'>Contact</FooterLink>
            <FooterLink to='/policies'>
              {intl.formatMessage({ id: 'policies' })}
            </FooterLink>
            <FooterLink
              target='_blank'
              href={
                intl.locale === 'fr-ca'
                  ? 'https://www.originehotels.com/fr/carte-cadeau'
                  : 'https://www.originehotels.com/en/carte-cadeau'
              }
            >
              {intl.formatMessage({ id: 'giftCard' })}
            </FooterLink>
            <FooterLink to='/press-review'>
              {intl.formatMessage({ id: 'pressReview' })}
            </FooterLink>
          </GridItem>
          <GridItem xs='1-2' sm='1-3' md='1-4' xlg='1-6'>
            <Heading>{intl.formatMessage({ id: 'apartment.many' })}</Heading>

            {apartmentLinks.map((node) => (
              <FooterLink to={node.link}>{node.label}</FooterLink>
            ))}
          </GridItem>
          <GridItem xs='1-1' sm='1-3' md='1-4' xlg='1-6'>
            <Heading>{intl.formatMessage({ id: 'address' })}</Heading>
            <Address
              href='https://www.google.com/maps/place/GH+Griffintown+Hotel/@45.49148,-73.5585421,15z/data=!4m16!1m7!3m6!1s0x4cc91a8a76b3a3e1:0xc1b204af6477ce81!2s1306+Basin+St,+Montreal,+QC+H3C+0P9!3b1!8m2!3d45.4914637!4d-73.5584282!3m7!1s0x4cc91becccfbcdcd:0xae0241da35d145f8!5m2!4m1!1i2!8m2!3d45.4914427!4d-73.5584031'
              target='_blank'
              rel='noopener'
            >
              1222 rue des Bassins
              <br />
              Montreal, Quebec
              <br />
              Canada H3C 0X7
            </Address>

            <Address href='tel:18555770222'>1-855-577-0222</Address>
          </GridItem>
          <GridItem xs='1-1' md='1-4' lg='1-2' className='u-hide-medium-down' />
        </GridRow>
      </Wrapper>
    </Container>
  )
}

export default Footer
