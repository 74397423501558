import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Portal extends React.Component {
  static propTypes = {
    selector: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }

  componentDidMount() {
    this.element = document.getElementById(this.props.selector)
    this.forceUpdate()
  }

  render() {
    if (this.element === undefined) {
      return null
    }

    return ReactDOM.createPortal(this.props.children, this.element)
  }
}

export default Portal
