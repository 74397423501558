import { css } from 'styled-components'
import { math } from 'polished'

import { media } from 'styles/media'

export default css`
  ${media.small`

    .u-push-1-8-sm {
      margin-left: ${math('1/8') * '100' + '%'} !important;
    }

    .u-push-1-6-sm {
      margin-left: ${math('1/6') * '100' + '%'} !important;
    }

  `};

  ${media.medium`

    .u-push-0-md {
      margin-left: 0 !important;
    }

    .u-push-1-12-md {
      margin-left: ${math('1/12') * '100' + '%'} !important;
    }

    .u-push-1-6-md {
      margin-left: ${math('1/6') * '100' + '%'} !important;
    }

  `};

  ${media.large`

    .u-push-1-12-lg {
      margin-left: ${math('1/12') * '100' + '%'} !important;
    }

    .u-push-1-6-lg {
      margin-left: ${math('1/6') * '100' + '%'} !important;
    }

  `};
`
