import React from 'react'
import { array } from 'prop-types'
import styled from 'styled-components'
import { stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

import Icon from 'components/Icon'

const Container = styled.div`
  display: flex;
  margin: -${stripUnit(spacing.tiny) * 1.5 + 'px'};

  ${media.medium`
    margin: -${spacing.small};
  `};
`

const Item = styled.div`
  padding: ${stripUnit(spacing.tiny) * 1.5 + 'px'};

  ${media.medium`
    padding: ${spacing.small};
  `};
`

const Link = styled.a`
  display: flex;
`

const SocialLinks = ({ items }) => (
  <Container>
    {items.map((item, i) => (
      <Item key={i}>
        <Link href={item.link} target='_blank' rel='noopener'>
          <Icon icon={item.icon} />
        </Link>
      </Item>
    ))}
  </Container>
)

SocialLinks.propTypes = {
  items: array.isRequired,
}

export default SocialLinks
