import React from 'react'
import styled from 'styled-components'
import { string, array, func } from 'prop-types'
import { stripUnit } from 'polished'
import { Link } from 'gatsby-plugin-intl'

import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'

const Container = styled.div`
  position: relative;

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

// const Label = styled(Link)`
//   display: block;
//   align-items: center;
//   cursor: pointer;
//   width: 100%;
// `

const Menu = styled.div`
  border-left: 1px solid ${borderColor};
  margin-top: ${spacing.small};
`

const Item = styled(Link)`
  display: block;
  padding: ${spacing.tiny} ${spacing.medium};
`

// const linkStyles = css`
//   display: block;
//   align-items: center;
//   cursor: pointer;
//   width: 100%;
// `

const components = {
  button: ({ children, ...props }) => <button {...props}>{children}</button>,
  link: ({ children, ...props }) => <Link {...props}>{children}</Link>,
  href: ({ children, ...props }) => <a {...props}>{children}</a>,
}

const MobileNavItem = ({ link, label, onClick, subLinks, tag, ...rest }) => {
  const Tag = components[tag || 'link']

  const props = {
    onClick,
    to: !tag || tag === 'link' ? link : undefined,
    ...rest,
  }

  return (
    <Container>
      <Tag {...props}>
        <Heading size={3}>{label}</Heading>
      </Tag>
      {subLinks && (
        <Menu>
          {subLinks.map((subLink, i) => (
            <Item key={i} to={subLink.link} onClick={onClick}>
              {subLink.label}
            </Item>
          ))}
        </Menu>
      )}
    </Container>
  )
}

MobileNavItem.propTypes = {
  link: string.isRequired,
  label: string.isRequired,
  subLinks: array,
  onClick: func,
  tag: string,
}

export default MobileNavItem
