import React, { useCallback, useEffect, useState } from 'react'
import { bool } from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'

import Button from 'components/button/Button'

const BookButton = ({ isSmall, url, target }) => {
  const intl = useIntl()
  const [gaUrlParam, setGaUrlParam] = useState(undefined)

  const parseGA = useCallback(() => {
    if (window && window.ga) {
      setGaUrlParam(window.ga.getAll()[0].get('linkerParam'))
    }
  }, [setGaUrlParam])

  useEffect(() => {
    window.addEventListener('load', parseGA, false)

    return () => {
      window.removeEventListener('load', parseGA, true)
    }
  }, [parseGA])

  const searchParams = new URLSearchParams()

  if (intl.locale && intl.locale === 'fr-ca')
    searchParams.append('languageID', '3')

  if (gaUrlParam) {
    const key = gaUrlParam.substring(0, 3)

    const value = gaUrlParam.substring(4)

    searchParams.append(key, value)
  }

  const params = searchParams.toString()

  let href = url || 'https://reservations.travelclick.com/112441'

  if (params && params.length > 0) {
    href += `?${params}`
  }

  const props = {
    theme: 'primary',
    target: target || '_blank',
    href,
    size: isSmall ? 'small' : null,
  }

  return (
    <>
      <Button {...props}>{intl.formatMessage({ id: 'home.bookNow' })}</Button>
    </>
  )
}

BookButton.propTypes = {
  isSmall: bool,
}

export default BookButton
