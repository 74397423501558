import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import { math } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1540px;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${media.small`
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${media.medium`
    padding-left: 5%;
    padding-right: 5%;
  `};
`

const Inner = styled.div`
  position: static;

  ${media.medium`
    ${props =>
      props.size === 'narrow' &&
      css`
        padding-left: ${math('1/12') * '100' + '%'} !important;
        padding-right: ${math('1/12') * '100' + '%'} !important;
      `}
  `};
`

const Wrapper = ({ children, size }) => (
  <Container>
    <Inner size={size}>{children}</Inner>
  </Container>
)

Wrapper.propTypes = {
  children: node.isRequired,
  size: bool,
}

export default Wrapper
