import React from 'react'
import styled, { css } from 'styled-components'
import { node, string } from 'prop-types'
import { mix, stripUnit } from 'polished'
import { Link } from 'gatsby-plugin-intl'

import { primaryColor, white, black } from 'styles/colors'
import {
  animationTime,
  animationCurve,
  inputHeight,
  inputHeightDesktop,
  borderRadius,
} from 'styles/variables'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

import SmallCaps from 'components/SmallCaps'

const Container = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  padding: 0 ${spacing.large};
  cursor: pointer;
  text-align: center;
  transition: all ${animationTime} ${animationCurve};

  ${media.medium`
    height: ${inputHeightDesktop};
  `};

  ${media.large`
    padding: 0 ${spacing.xLarge};
  `};

  ${props =>
    props.size === 'small' &&
    css`
      height: ${stripUnit(inputHeight) * 0.8 + 'px'};
      padding: 0 ${spacing.medium};

      ${media.medium`
        height: ${stripUnit(inputHeightDesktop) * 0.8 + 'px'};
        padding: 0 ${spacing.large};
      `};
    `}

  ${props =>
    props.theme === 'primary' &&
    css`
      color: ${white};
      background-color: ${primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, primaryColor, black)};
      }
    `}

  ${props =>
    props.theme === 'secondary' &&
    css`
      color: ${black};
      border: 2px solid ${black};

      &:hover,
      &:focus,
      &:active {
        color: ${white};
        background-color: ${black};
      }
    `}

  ${props =>
    props.theme === 'tertiary' &&
    css`
      color: ${white};
      border: 2px solid ${white};

      &:hover,
      &:focus,
      &:active {
        color: ${black};
        background-color: ${white};
      }
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const Label = styled(SmallCaps)`
  position: static;
`

const getElement = props => {
  if (props.to) return Link
  if (props.href) return 'a'
  return 'button'
}

const Button = ({
  children,
  theme = 'primary',
  size,
  type = 'button',
  ...rest
}) => (
  <Container
    theme={theme}
    size={size}
    as={getElement(rest)}
    type={type}
    {...rest}
  >
    <Label>{children}</Label>
  </Container>
)

Button.propTypes = {
  children: node.isRequired,
  theme: string,
  size: string,
}

export default Button
