import React from 'react'
import styled, { css } from 'styled-components'
import { string, array, bool } from 'prop-types'
import { stripUnit } from 'polished'
import { Link } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { white, greyDark } from 'styles/colors'
import { boxShadow } from 'styles/helpers'
import { animationTime, animationCurve } from 'styles/variables'
import * as spacing from 'styles/spacing'

import Icon from 'components/Icon'

const Container = styled.div`
  position: relative;
  display: flex;
`

const Dropdown = styled.div`
  position: relative;
  display: flex;

  &:hover:after,
  &:focus:after {
    content: '';
    position: absolute;
    top: -${spacing.medium};
    left: 0;
    right: 0;
    bottom: -${spacing.medium};
    display: block;
    cursor: pointer;
  }
`

const linkStyle = css`
  position: relative;
  z-index: 10; /* Position above after element */
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'};
  color: ${white};
  transition: color ${animationTime} ${animationCurve};

  &.${'active'} {
    position: static;
  }

  ${(props) =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  ${media.large`
    padding: ${spacing.medium};
  `};
`

const Label = styled(Link)`
  ${linkStyle}
`

const ExternalLink = styled.a`
  ${linkStyle}
`

const IconWrap = styled.div`
  display: flex;
  margin-left: ${spacing.small};
  position: relative;
  top: 2px; /* Align with label */
`

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 210px;
  background-color: ${white};
  padding: ${stripUnit(spacing.medium) * 1.25 + 'px'}
    ${stripUnit(spacing.small) * 1.5 + 'px'};
  transform: translateY(-${spacing.tiny});
  opacity: 0;
  visibility: hidden;
  transition: all ${animationTime} ${animationCurve};
  z-index: 10;
  margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
  ${boxShadow};

  ${media.large`
    margin-left: ${spacing.medium};
  `};

  ${Dropdown}:hover &,
  ${Dropdown}:focus & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`

const Item = styled(Link)`
  display: block;
  padding: ${spacing.tiny} ${stripUnit(spacing.small) * 1.5 + 'px'};
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${greyDark};
  }
`

const components = {
  link: ({ children, ...props }) => <Label {...props}>{children}</Label>,
  a: ({ children, ...props }) => (
    <ExternalLink {...props}>{children}</ExternalLink>
  ),
  href: ({ children, ...props }) => (
    <ExternalLink {...props}>{children}</ExternalLink>
  ),
}

const NavItem = ({ link, label, subLinks, isDark, tag, ...rest }) => {
  const ContainerEl = React.useMemo(() => {
    return subLinks ? Dropdown : Container
  }, [subLinks])
  const Tag = components[tag || 'link']

  const props = {
    to: !tag || tag === 'link' ? link : undefined,
    href: !tag || tag !== 'link' ? link : undefined,
    ...rest,
  }

  return (
    <ContainerEl>
      <Tag isDark={isDark} to={link} activeClassName='active' {...props}>
        {label}
        {subLinks && (
          <IconWrap>
            <Icon icon='dropdown' size='tiny' />
          </IconWrap>
        )}
      </Tag>
      {subLinks && (
        <Menu>
          {subLinks.map((subLink, i) => (
            <Item key={i} to={subLink.link}>
              {subLink.label}
            </Item>
          ))}
        </Menu>
      )}
    </ContainerEl>
  )
}

NavItem.propTypes = {
  link: string.isRequired,
  label: string.isRequired,
  subLinks: array,
  isDark: bool,
}

export default NavItem
