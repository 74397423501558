import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import { square } from 'styles/helpers'

// Icons
import bed from 'assets/icons/bed.svg'
import close from 'assets/icons/close.svg'
import dropdown from 'assets/icons/dropdown.svg'
import facebook from 'assets/icons/facebook.svg'
import fork from 'assets/icons/fork.svg'
import hamburger from 'assets/icons/hamburger.svg'
import twitter from 'assets/icons/twitter.svg'
import instagram from 'assets/icons/instagram.svg'
import leftArrow from 'assets/icons/left-arrow.svg'
import mail from 'assets/icons/mail.svg'
import occupants from 'assets/icons/occupants.svg'
import rightArrow from 'assets/icons/right-arrow.svg'
import shower from 'assets/icons/shower.svg'
import size from 'assets/icons/size.svg'
import sofa from 'assets/icons/sofa.svg'

const getSize = ({ size }) => {
  if (size === 'tiny') return '12px'
  if (size === 'small') return '16px'
  if (size === 'large') return '32px'
  return '24px'
}

const getIcon = (icon) => {
  switch (icon) {
    case 'bed':
      return bed
    case 'close':
      return close
    case 'dropdown':
      return dropdown
    case 'facebook':
      return facebook
    case 'fork':
      return fork
    case 'hamburger':
      return hamburger
    case 'twitter':
      return twitter
    case 'instagram':
      return instagram
    case 'mail':
      return mail
    case 'occupants':
      return occupants
    case 'left-arrow':
      return leftArrow
    case 'right-arrow':
      return rightArrow
    case 'shower':
      return shower
    case 'size':
      return size
    case 'sofa':
      return sofa
    default:
      return twitter
  }
}

const Container = styled(({ icon, size, ...rest }) => {
  const IconComponent = getIcon(icon)
  return <IconComponent {...rest} />
})`
  display: inline-block;
  vertical-align: top;
  fill: currentColor;
  ${square(getSize)};
`

function Icon(props) {
  return <Container {...props} />
}

Icon.propTypes = {
  icon: string.isRequired,
  size: string,
}

export default Icon
