import React from 'react'
import Helmet from 'react-helmet'
import { string } from 'prop-types'

import ShareImage from 'assets/images/share-image.jpg'

const MetaTags = ({ title, description, noindex }) => (
  <Helmet>
    {/* Title + description */}
    <title>{title}</title>
    <meta
      name='description'
      content={
        description ||
        'Introducing the first Condo Hotel in the heart of Griffintown, offering 126 stylishly furnished and fully-equipped apartments just a walk-away from downtown Montreal.'
      }
    />

    {noindex && <meta name='robots' content='noindex' />}

    {/* Facebook */}
    <meta property='og:title' content={title} />
    <meta
      property='og:description'
      content={
        description ||
        'Introducing the first Condo Hotel in the heart of Griffintown, offering 126 stylishly furnished and fully-equipped apartments just a walk-away from downtown Montreal.'
      }
    />
    <meta property='og:type' content='website' key='og:type' />

    <meta
      property='og:url'
      content='https://griffintownhotel.com/'
      key='og:url'
    />
    <meta property='og:image' content={ShareImage} key='og:image' />

    {/* Twitter */}
    <meta
      name='twitter:card'
      content='summary_large_image'
      key='twitter:card'
    />
    <meta
      name='twitter:url'
      content='https://griffintownhotel.com/'
      key='twitter:url'
    />
    <meta name='twitter:title' content={title} />
    <meta
      name='twitter:description'
      content={
        description ||
        'Introducing the first Condo Hotel in the heart of Griffintown, offering 126 stylishly furnished and fully-equipped apartments just a walk-away from downtown Montreal.'
      }
    />
    <meta name='twitter:image' content={ShareImage} key='twitter:image' />
  </Helmet>
)

MetaTags.propTypes = {
  title: string.isRequired,
}

MetaTags.defaultProps = {
  noindex: false,
}

export default MetaTags
